// Main scripts file
import './js/common';

// Main styles file
import './scss/common.scss';

// Font awesome icons
import './js/fontawesome';

import 'cookieconsent';
import 'cookieconsent/build/cookieconsent.min.css';

// gère l'affiche des images de fonds
function backgrounds() {
  let $elements = $("[data-background]");
  $elements.each(function () {
      if($(this).data('background')) {
        $(this).css('background-image', 'url(' + $(this).data('background') + ')');
        $(this).removeData('background');
      }

  });
}

$(window).on('load', function () {
  // affiche les images de fonds au chargement de la page
  backgrounds();

  $(window).on('scroll', function() {
    let position = 0;
    let ticking = false;
    let $header = $('#main-navbar');

    position = window.scrollY;
    if (!ticking) {
      window.requestAnimationFrame(function() {
        if(position > 0) {
          $header.addClass('ticking');
        } else {
          $header.removeClass('ticking');
        }
        ticking = false;
      });
    }
    ticking = true;
  }).scroll();
});

$(window).on('load', function () {
  $('input[type="file"]').change(function(e){
    var fileName = e.target.files[0].name;
    $('.custom-file-label').html(fileName);
  });

  window.cookieconsent.initialise({
    "palette": {
      "popup": {
        "background": "#2d2d2d",
        "text": "#ffffff"
      },
      "button": {
        "background": "#32f7d9",
        "text": "#ffffff"
      }
    },
    "position": "bottom",
    "content": {
      "message": "Questo sito fa uso di cookie per migliorare l’esperienza di navigazione degli utenti e per raccogliere informazioni sull’utilizzo del sito.",
      "dismiss": "OK",
      "link": "Scopri di più",
      "href": "https://empteezy.it/it/informazioni-legali"
    }
  });
});
